export default class PaymentsFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.reservationScheduleToken = "";
    this.payUserToken = "";
    this.clientToke = "";
    this.dateTimeFrom = "";
    this.dateTimeTo = "";
    this.textSearch = "";
  }
  fillData(data) {
    this.reservationScheduleToken = data.reservationScheduleToken;
    this.payUserToken = data.payUserToken;
    this.clientToke = data.clientToke;
    this.dateTimeFrom = data.dateTimeFrom;
    this.dateTimeTo = data.dateTimeTo;
    this.textSearch = data.textSearch;
  }
}
