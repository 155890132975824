<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="payments.paymentsData">
      <PaymentsTable
        :paymentsData="payments.paymentsData"
        v-on:setPaymentData="paymentData.fillData($event)"
        :paymentData="paymentData"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="payments.pagination"
        v-on:changePage="changePage"
      />
      <PaymentInfo :paymentData="paymentData" />
      <PaymentRefund
        :paymentData="paymentData"
        v-on:refresh="getAllPayments()"
      />
    </div>

    <PaymentFloatBtns
      :theFilterData="payments.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import PaymentsTable from "@/components/reservations/payments/PaymentsTable.vue";
import PaymentInfo from "@/components/reservations/payments/PaymentInfo.vue";
import PaymentRefund from "@/components/reservations/payments/PaymentRefund.vue";
import PaymentFloatBtns from "@/components/reservations/payments/PaymentFloatBtns.vue";
import Pagination from "@/components/general/Pagination.vue";
import Payments from "@/models/reservations/payments/Payments";
import Payment from "@/models/reservations/payments/Payment";

export default {
  name: "Payments",
  components: {
    PreLoader,
    ExceptionWithImg,
    PaymentsTable,
    PaymentInfo,
    PaymentRefund,
    PaymentFloatBtns,
    Pagination,
  },
  data() {
    return {
      isClient: false,
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      payments: new Payments(),
      paymentData: new Payment(),
    };
  },
  methods: {
    async setUserType() {
      if (this.$store.getters.userData.clientPersonalData) {
        this.isClient = true;
      }
    },
    changePage(page) {
      this.payments.pagination.selfPage = page;
      this.getAllPayments();
    },
    search(data) {
      this.payments.filterData.fillData(data);
      this.payments.paymentsData = null;
      this.getAllPayments();
    },
    async getAllPayments() {
      this.isLoading = true;
      if (this.isClient) {
        this.payments.filterData.clientToken =
          this.$store.getters.userData.clientPersonalData.clientToken;
      }
      try {
        const response = await this.payments.payment.getAllPayments(
          this.language,
          this.userAuthorizeToken,
          this.payments.pagination,
          this.payments.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.payments.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.payments.paymentsData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.payments.paymentsData = null;
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.payments.paymentsData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.payments.paymentsData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },

  computed: {},
  async created() {
    this.setUserType();
    this.getAllPayments();
  },
};
</script>
