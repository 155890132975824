<template>
  <b-modal id="PaymentRefund" scrollable centered hide-footer>
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <template #modal-title>
      <h3>
        <img src="@/assets/images/alert.svg" class="icon-lg" />
        {{ $t("alert") }}
      </h3>
    </template>
    <div class="row">
      <CustomInput
        :className="'col-md-12'"
        :id="'refundReasonAr'"
        :value="paymentData.refundReasonAr"
        :title="$t('payments.refundReasonAr')"
        :imgName="'reason.svg'"
        v-on:changeValue="paymentData.refundReasonAr = $event"
      />
      <CustomInput
        :className="'col-md-12'"
        :id="'refundReasonEn'"
        :value="paymentData.refundReasonEn"
        :title="$t('payments.refundReasonEn')"
        :imgName="'reason.svg'"
        v-on:changeValue="paymentData.refundReasonEn = $event"
      />
      <CustomInput
        :className="'col-md-12'"
        :id="'refundReasonUnd'"
        :value="paymentData.refundReasonUnd"
        :title="$t('payments.refundReasonUnd')"
        :imgName="'reason.svg'"
        v-on:changeValue="paymentData.refundReasonUnd = $event"
      />
      <TextArea
        :className="'col-md-12'"
        :id="'refundNotes'"
        :value="paymentData.refundNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
        v-on:changeValue="paymentData.refundNotes = $event"
      />
    </div>
    <div class="modal-footer">
      <button
        name="submit"
        type="submit"
        class="btn btn-submit"
        @click.prevent="refundPayment"
      >
        {{ $t("refund") }}
      </button>
      <button
        name="submit"
        type="submit"
        class="btn btn-cancel"
        @click.prevent="$bvModal.hide('PaymentRefund')"
      >
        {{ $t("cancel") }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import createToastMixin from "@/utils/create-toast-mixin";
import TextArea from "@/components/general/TextArea.vue";
import CustomInput from "@/components/general/CustomInput.vue";

export default {
  name: "PaymentRefund",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomInput,
    TextArea,
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
    };
  },
  methods: {
    async refundPayment() {
      this.isLoading = true;
      console.log(this.paymentData);
      try {
        let response = await this.paymentData.refundPayment(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.hide("PaymentRefund");
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        console.log(e);
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  props: ["paymentData"],
  computed: {},
};
</script>

<style scoped lang="scss"></style>
